//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

const apiTivos = process.env.REACT_APP_API

//============================================================
//  Trae información de la base de datos
//============================================================
export function GetUserVars(data,res){
    let request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({key:data})
    }

    fetch(apiTivos+'/userData/UserInfo', request)
    .then(response => response.json())
    .then(dataSet => res(dataSet));
};

export function GetClientInfo(data,res){
    let request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({key:data.formId})
    }

    fetch(apiTivos+'/userData/ClientInfo', request)
    .then(response => response.json())
    .then(dataSet => res(dataSet));
};
