//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//  June 2023 : Search bar hidden.
//=====================================================//
const apiTivos = process.env.REACT_APP_API

export function closeSession(){
    let token = sessionStorage.getItem('token');
    let request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({key:token})
    }
    fetch(apiTivos+'/userData/sessionEnd', request)
    .then(response => response.json())
    .then(data => console.log(data));

    sessionStorage.clear();
};