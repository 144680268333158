//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React, {useState, useEffect} from 'react'

import { Link } from 'react-router-dom'

import './sidebar.css'

import logo from '../../assets/images/tivos-dark-logo.png'

import SidebarItem from './SidebarItem'
import sidebar_items_client from '../../assets/JsonData/sidebar_routes.json'
import sidebar_items_broker from '../../assets/JsonData/sidebar_routes_broker.json'

import { closeSession } from '../../ExternalConnections/SessionEnd.jsx'
import { GetUserVars } from '../../ExternalConnections/InitialDataLoad'

const home = process.env.REACT_APP_HOME

const urlParams = new URLSearchParams(window.location.search);
const host = window.location.origin
const token = urlParams.get('id');

const gotoMain = () => {
    closeSession();
    window.location.href = home;
}

const goTo = (elm) => {
    if(elm.sidebarId == 'dashboard'){
        window.open(host+'/Dashboard?id='+token,'_self');
    }else if(elm.sidebarId == 'clients'){
        window.open(host+'/Clients?id='+token,'_self');
    }else if(elm.sidebarId == 'application'){
        window.open(host+'/Application?id='+token,'_self');

    }
};

GetUserVars(token,(response) => {
    sessionStorage.setItem('userInfo', JSON.stringify(response.company[0]));
    if(response.company[0].userType == 1){
        sessionStorage.setItem('fase',JSON.stringify(response.stage[0].fase))
        sessionStorage.setItem('tasks',JSON.stringify(response.tasks))
        sessionStorage.setItem('supplier',JSON.stringify(response.supplier[0]))
        sessionStorage.setItem('uploadedFiles',JSON.stringify(response.uploadedFiles))
    }else if(response.company[0].userType == 2){
        sessionStorage.setItem('clients',JSON.stringify(response.clients))
    }else if(response.company[0].userType == 3){
        sessionStorage.setItem('fase',JSON.stringify(response.stage[0].fase))
        sessionStorage.setItem('tasks',JSON.stringify(response.tasks))
        sessionStorage.setItem('uploadedFiles',JSON.stringify(response.uploadedFiles))
    }
})

const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

var sideBarItems;

if(userInfo == null){
    setTimeout(() => {
        window.location.reload()
    },500)

}else{
    if(userInfo.userType == 1 || userInfo.userType == 3){
       sideBarItems = (sidebar_items_client)
    }else if(userInfo.userType == 2){
       sideBarItems = (sidebar_items_broker)
    }
}

const Sidebar = props => {
    
    const activeItem = sideBarItems.findIndex(item => item.route === props.location.pathname)

    return (
        <div className='sidebar'>
            <div className="sidebar__logo">
                <img src={logo} alt="company logo" onClick={gotoMain}/>
            </div>
            {
                sideBarItems.map((item, index) => (
                    <Link onClick={() => goTo(item)} to='' key={index}>
                        <SidebarItem
                            title={item.display_name}
                            icon={item.icon}
                            sidebarId={item.sidebarId}
                            active={index === activeItem}
                        />
                    </Link>
                ))
            }
        </div>
    )
}

export default Sidebar
