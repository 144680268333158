//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React, {useEffect,useState} from 'react'

import Table from '../components/table/Table'

const customerTableHead = [
    '',
    'Nombre',
    'Correo',
    'Telefono',
    'Total Arrendamiento',
    'Estatus'
]

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('id');

const showClient = (data) => {
    sessionStorage.setItem('clientInfo',JSON.stringify(data))
    sessionStorage.setItem('formId',data.formId)
    window.location.href = `/CientDashboard?id=${token}`
};

const renderHead = (item, index) => <th key={index}>{item}</th>

const renderBody = (item, index) => (
    <tr key={index} onClick={() => showClient(item)}>
        <td>{item.id}</td>
        <td>{item.name}</td>
        <td>{item.email}</td>
        <td>{item.phone}</td>
        <td>{item.total_spend}</td>
        <td className={item.class}>{item.status}</td>
    </tr>
)

const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))

const Clients = () => {
    if(userInfo.userType == 2){
        const clients = JSON.parse(sessionStorage.getItem('clients'))
        return (
            <div>
                <h2 className="page-header">
                    Clientes
                </h2>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card__body">
                                <Table
                                    limit='10'
                                    headData={customerTableHead}
                                    renderHead={(item, index) => renderHead(item, index)}
                                    bodyData={clients}
                                    renderBody={(item, index) => renderBody(item, index)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Clients
