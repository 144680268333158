//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//  June 2023 : Search bar hidden.
//=====================================================//

import { closeSession } from '../../ExternalConnections/SessionEnd.jsx'

const login = process.env.REACT_APP_ENV_URL

export function goToLogin(){
    closeSession();
    window.location.href = login;
 };
