//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React from 'react'
import ReactDOM from 'react-dom'


import './uploadcard.css'

const UploadCard = props => {

    const verify = (elm) => {
        
        let cardId = elm.props.cardId;
        sessionStorage.setItem('card',elm.props.cardId)
        //if(!document.getElementById(cardId).classList.contains("status-card-pending")){
            if (cardId == 'buroEmpresa'){
                document.getElementById("buroEmpresarialForm").style.display = "flex";
                document.getElementById("buroAvalForm").style.display = "none";
                document.getElementById("satForm").style.display = "none";
                document.getElementById("fase1").style.display = "none";
                document.getElementById('left-arrow').style.display = 'none'
                document.getElementById('right-arrow').style.display = 'none'
            }else if(cardId == 'buroAval'){
                document.getElementById("buroAvalForm").style.display = "flex";
                document.getElementById("buroEmpresarialForm").style.display = "none";
                document.getElementById("satForm").style.display = "none";
                document.getElementById("fase1").style.display = "none";
                document.getElementById('left-arrow').style.display = 'none'
                document.getElementById('right-arrow').style.display = 'none'
            }else if(cardId == 'ciec'){
                document.getElementById("satForm").style.display = "flex";
                document.getElementById("buroEmpresarialForm").style.display = "none";
                document.getElementById("buroAvalForm").style.display = "none";
                document.getElementById("fase1").style.display = "none";
                document.getElementById('left-arrow').style.display = 'none'
                document.getElementById('right-arrow').style.display = 'none'
            }else if(cardId == 'contCPP'){
                let popUp = document.getElementById('popUp');
                let cardTitle = document.getElementById('popUpTitle');
                // let cardId = sessionStorage.getItem('card');
                let startButton = document.getElementById('typeformButton');
                let uploadInput = document.getElementById('uploadFileLabel');

                uploadInput.style.display = 'none';
                startButton.style.display = 'flex';

                cardTitle.innerHTML = elm.props.title

                if(getComputedStyle(popUp).display == 'none'){
                    popUp.style.display = 'block';
                    document.getElementById('costumerView').style.filter = 'blur(5px)';
                    document.getElementById(cardId+'-copy').style.display = 'block';
                    document.getElementById('sendButton').style.display = 'none';
                };
            }else if(cardId == 'contProv'){
                let popUp = document.getElementById('popUp');
                let cardTitle = document.getElementById('popUpTitle');
                // let cardId = sessionStorage.getItem('card');
                let proveedorForm = document.getElementById('proveedorForm');
                let proveedorFormSend = document.getElementById('proveedorFormSend');
                let uploadInput = document.getElementById('uploadFileLabel');

                uploadInput.style.display = 'none';
                proveedorForm.style.display = 'flex';
                proveedorFormSend.style.display = 'flex';

                cardTitle.innerHTML = elm.props.title

                if(getComputedStyle(popUp).display == 'none'){
                    popUp.style.display = 'block';
                    document.getElementById('costumerView').style.filter = 'blur(5px)';
                    document.getElementById('sendButton').style.display = 'none';
                };
            }else{
                let popUp = document.getElementById('popUp');
                let cardTitle = document.getElementById('popUpTitle');
                let cardId = sessionStorage.getItem('card');
                let fileListDiv = document.getElementById('filesList')
                
                try{
                    
                    let files = JSON.parse(sessionStorage.getItem(cardId+'-Files'))
                    if(files != null){
                        for(let i = 0; i < files.length; i++){
                            fileListDiv.innerHTML += `<li class='fileList'>${files[i]}<a id='${files[i]}' class='deleteButton'>X</a><li>`
                        };
                    }
                }catch{
                    console.log('NO hay archivos')
                }
        
                cardTitle.innerHTML = elm.props.title

                if(getComputedStyle(popUp).display == 'none'){
                    popUp.style.display = 'block';
                    document.getElementById('costumerView').style.filter = 'blur(5px)';
                    document.getElementById(cardId+'-copy').style.display = 'block';
                };

            };
        //};
    };

    return (
        <div className={`${props.class}`} onClick={() => verify({props})} id={`${props.cardId}`}>
            <div className="status-card__icon">
                <i className={props.icon}></i>
            </div>
            <div className="status-card__info">
                <h3>{props.title}</h3>
            </div>
        </div>
    )
}

export default UploadCard
