//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//  Multiple uploads is restricted due to Monday.com complications DR 13-08-2023
// 
//=====================================================//


import ThemeReducer from "./ThemeReducer"
import { combineReducers } from "redux"

const rootReducer = combineReducers({ThemeReducer})

export default rootReducer