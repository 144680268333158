//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React from 'react'

import { Route, Switch } from 'react-router-dom'

import Dashboard from '../pages/Dashboard'
import ClientDashboard from '../pages/DashboardClient'
import Clients from '../pages/Clients'
import Application from '../pages/Application'


//=====================================================//
//    Verifica si hay una sesion iniciada.
//=====================================================//

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('id');

const login = process.env.REACT_APP_ENV_URL;

if(token == null){
    document.body.style.filter = 'blur(5px)'
    window.location = login;
};

//=====================================================//

const Routes = () => {

    return (
        <Switch>
            {/* <Route path='/Home' exact component={Home}/> */}
            <Route path='/Dashboard' component={Dashboard}/>
            <Route path='/Clients' component={Clients}/>
            <Route path='/Application' component={Application}/>
            <Route path='/CientDashboard' component={ClientDashboard}/>
        </Switch>
    )
}

export default Routes
