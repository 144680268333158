//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//  June 2023 : Search bar hidden.
//=====================================================//

import React,{useState,useEffect} from 'react'

import './topnav.css'

import { Link } from 'react-router-dom'

import Dropdown from '../dropdown/Dropdown'

import notifications from '../../assets/JsonData/notification.json'

import user_image from '../../assets/images/account.svg'

import user_menu from '../../assets/JsonData/user_menus.json'

import {goToLogin} from './TopNavFunc.jsx'
import { GetUserVars } from '../../ExternalConnections/InitialDataLoad'

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('id');

//===============================================
// Notifications menu
//===============================================

const renderNotificationItem = (item, index) => (
    <div className="notification-item" key={index}>
        <i className={item.icon}></i>
        <span>{item.content}</span>
    </div>
)


//===============================================
// User menu image and name
//===============================================

const renderUserToggle = (userInfo) => (
    <div className="topnav__right-user">
        <div className="topnav__right-user__image">
            <img src={user_image} alt="" />
        </div>
        <div className="topnav__right-user__name">
            {userInfo.userName}
        </div>
    </div>
)

//===============================================
// User menu options
//===============================================

const renderUserMenu =(item, index) => (
    <Link to='/' key={index}>
        <div className="notification-item">
            <i className={item.icon}></i>
            <span onClick={goToLogin}>{item.content}</span>
        </div>
    </Link>
)

//===============================================

const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

const Topnav = () => {
    
    return (
        <div className='topnav'>
            
            <div className="topnav__search">
                {/* <input type="text" placeholder='Search here...' />
                <i className='bx bx-search'></i> */}
            </div>
            <div className="topnav__right">
                <div className="topnav__right-item">
                    {/* dropdown here */}
                    <Dropdown
                        customToggle={() => renderUserToggle(userInfo)}
                        contentData={user_menu}
                        renderItems={(item, index) => renderUserMenu(item, index)}
                    />
                </div>
                <div className="topnav__right-item">
                    <Dropdown
                        icon='bx bx-bell'
                        badge=''
                        contentData={notifications}
                        renderItems={(item, index) => renderNotificationItem(item, index)}
                        renderFooter={() => <Link to='/'>Ver Todos</Link>}
                    />
                    {/* dropdown here */}
                </div>
            </div>
        </div>
    )
}

export default Topnav
