//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//  Multiple uploads is restricted due to Monday.com complications DR 13-08-2023
// 
//=====================================================//

import React, {useState, useEffect} from 'react'

import { Link } from 'react-router-dom'

import { useSelector } from 'react-redux'

import {PopupButton} from '@typeform/embed-react'
import validator from 'validator'

import StatusCard from '../components/UploadCard/uploadCard'

import Table from '../components/table/Table'

import Badge from '../components/badge/Badge'

import Fase1 from '../assets/JsonData/Fase1Data.json'
import Fase2 from '../assets/JsonData/Fase2Data.json'
import Fase3 from '../assets/JsonData/Fase3Data.json'
import Fase4 from '../assets/JsonData/Fase4Data.json'

import { goToLogin } from '../components/topnav/TopNavFunc'

import logo from '../assets/images/tivos-dark-logo.png'
import whatsApp from '../assets/images/whatsapp.png'

import { GetClientInfo } from '../ExternalConnections/InitialDataLoad'

const apiTivos = process.env.REACT_APP_API
const apiFiles = process.env.REACT_APP_API_FILES
const proveedorApi = process.env.REACT_APP_PROVEEDOR

const formId = JSON.parse(sessionStorage.getItem('clientInfo'));

const ClientDashboard = () => {

    //==============================================================
    // Load initial data
    //==============================================================

    const [taskData, setTaskData] = useState([]);
    const [userData, setUserData] = useState([]);
    
    useEffect(() => {

        GetClientInfo(formId,(response) => {
           
            const userInfo = response.company[0];
            const taskInfo = response.tasks;
            const fase = response.stage[0];
            const supplier = response.supplier[0];
            const uploadedFiles = response.uploadedFiles;

            if(uploadedFiles != undefined){
                
                for(let i = 0; i < uploadedFiles.length; i++){
                    try{
                        let parsedInfo = uploadedFiles[i]
                        let cardId = parsedInfo.cardId+'-Files'
                        let files = []

                        for(let j = 1; j <= Object.keys(parsedInfo).length; j++){
                            let file = Object.values(parsedInfo)[j];
                            if(file != null){
                                files.push(file)
                            }
                        }
                        sessionStorage.setItem(cardId,JSON.stringify(files))
                    }catch{
                        console.log('No hay archivos')
                    }
                }
            };

            setTaskData(taskInfo)
            setUserData(userInfo)
            
            if(userInfo.userType == 3){
                document.getElementById('faseRoad').style.display = 'none'
                document.getElementById('fase1').style.display = 'none'
                document.getElementById('fase4').style.display = 'flex'
            }else{
                if(fase >= 2){
                    document.getElementById('pagination').style.display = 'flex';
                    document.getElementById('fase2icon').classList.add("active");
                    document.getElementById('fase2icon').classList.remove("inactive");    
                    document.getElementById('fase1-2').value = 1;    
                    document.getElementById('fase3icon').classList.add("active");
                    document.getElementById('fase3icon').classList.remove("inactive");    
                    document.getElementById('fase2-3').value = 1;    

                    document.getElementById('nombreEmpresaProveedor').value = supplier.company;
                    document.getElementById('nombreContactoProveedor').value = supplier.name;
                    document.getElementById('apellidoContactoProveedor').value = supplier.lastName;
                    document.getElementById('telefonoProveedor').value = supplier.phoneNumber;
                    document.getElementById('correoProveedor').value = supplier.email;
                };
            };

            if(taskInfo.length >= 0){
                setTimeout(() => {
                    window.location.hash = 'reload';
                },100)
                taskInfo.map((item,index)=>{
                    // Fase 1
                    if(item.appId === 'buroEmpresa')Fase1[0]["class"] = 'status-card'+item.status
                    if(item.appId === 'buroAval')Fase1[1]["class"] = 'status-card'+item.status
                    if(item.appId === 'activo')Fase1[2]["class"] = 'status-card'+item.status
                    if(item.appId === 'ciec')Fase1[3]["class"] = 'status-card'+item.status
                    if(item.appId === 'financieros')Fase1[4]["class"] = 'status-card'+item.status
                    if(item.appId == 'cuenta')Fase1[5]["class"] = 'status-card'+item.status
                    if(item.appId === 'cv')Fase1[6]["class"] = 'status-card'+item.status
                    // Fase 2
                    if(item.appId === 'actas')Fase2[0]["class"] = 'status-card'+item.status
                    if(item.appId === 'domEmpresa')Fase2[1]["class"] = 'status-card'+item.status
                    if(item.appId === 'domAval')Fase2[2]["class"] = 'status-card'+item.status
                    if(item.appId === 'csfAval')Fase2[3]["class"] = 'status-card'+item.status
                    if(item.appId === 'idAval')Fase2[4]["class"] = 'status-card'+item.status
                    if(item.appId === 'idRepLegal')Fase2[5]["class"] = 'status-card'+item.status
                    if(item.appId === 'rfcRepLegal')Fase2[6]["class"] = 'status-card'+item.status
                    if(item.appId === 'podRepLegal')Fase2[7]["class"] = 'status-card'+item.status
                    // if(item.appId === 'comRiesgos')Fase2[8]["class"] = 'status-card'+item.status
                    if(item.appId === 'propComFirm')Fase2[8]["class"] = 'status-card'+item.status
                    // Fase 3
                    if(item.appId === 'contCPP')Fase3[0]["class"] = 'status-card'+item.status
                    if(item.appId === 'contProv')Fase3[1]["class"] = 'status-card'+item.status
                    // Fase 4
                    if(item.appId === 'proOpinion')Fase4[0]["class"] = 'status-card'+item.status
                    if(item.appId === 'proConstanciaEmp')Fase4[1]["class"] = 'status-card'+item.status
                    if(item.appId === 'proConstanciaRep')Fase4[2]["class"] = 'status-card'+item.status
                    if(item.appId === 'proIneRep')Fase4[3]["class"] = 'status-card'+item.status
                    if(item.appId === 'proDomicilio')Fase4[4]["class"] = 'status-card'+item.status
                    if(item.appId === 'proEdoCuenta')Fase4[5]["class"] = 'status-card'+item.status
                    if(item.appId === 'proActas')Fase4[6]["class"] = 'status-card'+item.status
                    if(item.appId === 'proPoderes')Fase4[7]["class"] = 'status-card'+item.status
                    if(item.appId === 'proFicha')Fase4[8]["class"] = 'status-card'+item.status

                })
            };  
        });      
    },[]);
    //==============================================================

    //==============================================================
    // Go Back from buro and SATws forms
    //==============================================================

    const goBack = () => {
        let card = sessionStorage.getItem('card');

        if(card === 'buroEmpresa'){
            document.getElementById("buroEmpresarialForm").style.display = "none";
            document.getElementById("fase1").style.display = "flex";
            document.getElementById(card).classList.add("status-card-pending");
            document.getElementById(card).classList.remove("status-card");    
            document.getElementById('right-arrow').style.display = 'flex'
        };
        if(card === 'buroAval'){
            document.getElementById("buroAvalForm").style.display = "none";
            document.getElementById("fase1").style.display = "flex";
            document.getElementById(card).classList.add("status-card-pending");
            document.getElementById(card).classList.remove("status-card");    
            document.getElementById('right-arrow').style.display = 'flex'            
        };
        if(card === 'ciec'){
            document.getElementById("satForm").style.display = "none";
            document.getElementById("fase1").style.display = "flex";
            document.getElementById(card).classList.add("status-card-pending");
            document.getElementById(card).classList.remove("status-card");    
            document.getElementById('right-arrow').style.display = 'flex'
        };
        if(card === 'contCPP'){
            document.getElementById("typeformCobranza").style.display = "none";
            document.getElementById("fase3").style.display = "flex";
            document.getElementById(card).classList.add("status-card-pending");
            document.getElementById(card).classList.remove("status-card");
            document.getElementById('left-arrow').style.display = 'flex';
        };

        var formData = new FormData();

        formData.append('companyName',userData.nombreEmpresa)
        formData.append('formId',formId)
        formData.append('cardId',card)

        var requestOptions = {
        method: 'POST',
        body: formData,
        };

        fetch(apiFiles+"/monday/UploadClientFile", requestOptions)
        .then(response => response.text())
        .then(result => {console.log(result)})
        .catch(error => console.log('error', error));
    };

    //====================================================================

    //====================================================================
    //    File handlig on input change
    //====================================================================

    const [selectedFile, setSelectedFile] = useState();

    // const handleDragOver = (e) =>{
    //     e.preventDefault();
    //     // e.stopPropagation();
    // };

    // const handleDrop = (e) => {
    //     e.preventDefault();
    //     // e.stopPropagation();

    //     let files = e.dataTransfer.files;
    //     let cardId = sessionStorage.getItem('card');

    //     var formData = new FormData();

    //     for(let i = 0; i < files.length; i++){
    //         formData.append("file", files[i]);
    //     };
    //     formData.append('companyName',userData.nombreEmpresa)
    //     formData.append('formId',formId)
    //     formData.append('cardId',cardId)

    //     var requestOptions = {
    //     method: 'POST',
    //     body: formData,
    //     };
        
    //     if(files.length > 1){
    //         alert("Solo puedes subir un documento a la vez.")
    //     }else{
    //         fetch(apiTivos+"/monday/UploadFile", requestOptions)
    //         .then(response => response.text())
    //         .then(result => {
    //             console.log(result)
    //             document.getElementById(cardId).classList.add("status-card-pending");
    //             document.getElementById(cardId).classList.remove("status-card");
        
    //         })
    //         .catch(error => {alert("No tienes conexion a internet: Por favor verifica que tu conexion sea estable")});
    //     }

        

    //     closePopup();
    // };
    
    const closePopup = () => {
        // Copies Fase 1
        document.getElementById("popUp").style.display = 'none';
        document.getElementById('costumerView').style.filter = 'none';
        document.getElementById('activo-copy').style.display = 'none';
        document.getElementById('financieros-copy').style.display = 'none';
        document.getElementById('cuenta-copy').style.display = 'none';
        document.getElementById('cv-copy').style.display = 'none';
        document.getElementById('uploadFileLabel').style.display = 'flex';
        document.getElementById('typeformButton').style.display = 'none';
        document.getElementById('contCPP-copy').style.display = 'none';

        // Copies Fase 2
        document.getElementById('actas-copy').style.display = 'none';
        document.getElementById('domEmpresa-copy').style.display = 'none';
        document.getElementById('domAval-copy').style.display = 'none';
        document.getElementById('csfAval-copy').style.display = 'none';
        document.getElementById('idAval-copy').style.display = 'none';
        document.getElementById('idRepLegal-copy').style.display = 'none';
        document.getElementById('rfcRepLegal-copy').style.display = 'none';
        document.getElementById('podRepLegal-copy').style.display = 'none';
        document.getElementById('propComFirm-copy').style.display = 'none';

        // Copies Fase 4
        document.getElementById('proOpinion-copy').style.display = 'none';
        document.getElementById('proConstanciaEmp-copy').style.display = 'none';
        document.getElementById('proConstanciaRep-copy').style.display = 'none';
        document.getElementById('proIneRep-copy').style.display = 'none';
        document.getElementById('proDomicilio-copy').style.display = 'none';
        document.getElementById('proEdoCuenta-copy').style.display = 'none';
        document.getElementById('proActas-copy').style.display = 'none';
        document.getElementById('proPoderes-copy').style.display = 'none';
        document.getElementById('proFicha-copy').style.display = 'none';

        // Copies File Handling
        document.getElementById('proveedorForm').style.display = 'none';
        document.getElementById('proveedorFormSend').style.display = 'none';
        document.getElementById('sendButton').style.display = 'block';
        document.getElementById('filesList').innerHTML = '';
    };


    const fileHandling = (e) => {

        let files = e.target.files;
        let cardId = sessionStorage.getItem('card');
        let fileListDiv = document.getElementById('filesList');
        let fileList = []

        var formData = new FormData();

        for(let i = 0; i < files.length; i++){
            fileList.push(files[i].name)
            sessionStorage.setItem(cardId+'-Files',JSON.stringify(fileList))
            fileListDiv.innerHTML += `<li class='fileList'>${files[i].name}<a class='deleteButton'>X</a><li>`

            formData.append("file", files[i]);
        };


        formData.append('companyName',userData.nombreEmpresa)
        formData.append('formId',formId)
        formData.append('cardId',cardId)

        var requestOptions = {
        method: 'POST',
        body: formData,
        };

        fetch(apiFiles+"/monday/UploadClientFile", requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log(result)
            document.getElementById(cardId).classList.add("status-card-pending");
            document.getElementById(cardId).classList.remove("status-card");
    
        })
        .catch(error => {alert("El servicio se encuentra temporalmente fuera de línea. Por favor intenta más tarde.")});

        // closePopup();
    };

    //====================================================================

    //====================================================================
    // Sync files with monday
    //====================================================================

    const sendFiles = () => {
        let cardId = sessionStorage.getItem('card');
        let fileList = JSON.parse(sessionStorage.getItem(cardId+'-Files'))
        
        var requestOptions = {
        method: 'POST',
        headers:{
            'Content-Type':'application/json'
        },
        body: JSON.stringify({
            companyName:userData.nombreEmpresa,
            formId:formId,
            cardId:cardId,
            files:fileList
        }),
        };

        fetch(apiFiles+"/monday/SyncClientFiles", requestOptions)
        .then(response => response.text())
        .then(result => {
            closePopup();
        })
        .catch(error => {alert("El servicio se encuentra temporalmente fuera de línea. Por favor intenta más tarde.")});
    };

   
    //====================================================================
    // Delete file from list
    //====================================================================

    window.addEventListener("click", function(evt){
        if(evt.target.className == 'deleteButton'){
            try{

                let fileId = evt.target.id;
                let cardId = sessionStorage.getItem('card')
                let fileList = JSON.parse(sessionStorage.getItem(cardId+'-Files'))
                let fileIndex = fileList.indexOf(fileId)
                let newList = []

                fileList.splice(fileIndex,1)
                //if(fileList.length == 0) 
                sessionStorage.removeItem(cardId+'-Files')

                //sessionStorage.setItem(cardId+'-Files',(newList))

                let requestOptions = {
                    method: 'POST',
                    headers:{
                        'Content-Type':'application/json'
                    },
                    body: JSON.stringify({
                        formId:formId,
                        cardId:cardId,
                        file:fileId
                    }),
                
                }

                fetch(apiFiles+"/monday/DeleteFile",requestOptions)
                .then(response => response.text())
                .then(result => {
                    console.log(result)
                    window.location.reload();
                    closePopup();
                })
                

            }catch{
                console.log('No hay nada que hacer')
            }
        };
    });
    //====================================================================
    
    //====================================================================

    //====================================================================
    //    Pagination 
    //====================================================================

        const antFase = () => {
            let fase1 = document.getElementById('fase1');
            let fase2 = document.getElementById('fase2');
            let fase3 = document.getElementById('fase3');

            if(fase2.style.display === 'flex'){
                fase1.style.display = 'flex'
                fase2.style.display = 'none'
                document.getElementById('left-arrow').style.display = 'none'
                document.getElementById('right-arrow').style.display = 'flex'
            } else if(fase3.style.display === 'flex'){
                fase2.style.display = 'flex'
                fase3.style.display = 'none'
                document.getElementById('left-arrow').style.display = 'flex'
                document.getElementById('right-arrow').style.display = 'flex'
            }
        };

        const sigFase = () => {
            let fase1 = document.getElementById('fase1');
            let fase2 = document.getElementById('fase2');
            let fase3 = document.getElementById('fase3');

            if(fase1.style.display === 'flex'){
                fase1.style.display = 'none'
                fase2.style.display = 'flex'
                document.getElementById('left-arrow').style.display = 'flex'
                document.getElementById('right-arrow').style.display = 'flex'
            }else if(fase2.style.display === 'flex'){
                fase2.style.display = 'none'
                fase3.style.display = 'flex'
                document.getElementById('left-arrow').style.display = 'flex'
                document.getElementById('right-arrow').style.display = 'none'
            }

        };

    //====================================================================


    //====================================================================
    //    Whatsapp handling
    //====================================================================
    
    //Set your APP_ID
    var APP_ID = "qkws515p";

    window.intercomSettings = {
        app_id: APP_ID,
        name: userData.userName, // Full name
        email: userData.userEmail, // Email address
        user_id: userData.userEmail // current_user_id
    };
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();


    //====================================================================

    //====================================================================
    // Tarjeta de Contacto de Cuentas Por Pagar 
    //====================================================================
    
    const lockCard = (elm) => {
        if(elm.target.id === ''){
            if(elm.target.className === 'typeform'){
                document.getElementById('contCPP').classList.add("status-card-pending");
                closePopup();
                var formData = new FormData();

                formData.append('companyName',userData.nombreEmpresa)
                formData.append('formId',formId)
                formData.append('cardId','contCPP')
        
                var requestOptions = {
                method: 'POST',
                body: formData,
                };
        
                fetch(apiFiles+"/monday/UploadClientFile", requestOptions)
                .then(response => response.text())
                .then(result => {console.log(result)})
                .catch(error => console.log('error', error));
        
            }
        }else{
            console.log(elm)
        }
    }
    
    //====================================================================
    
    //====================================================================
    // Guardar la informacion del proveedor
    //====================================================================
    const saveProveedorInfo = () => {
        let nombreEmpresaProveedor = document.getElementById('nombreEmpresaProveedor').value
        let nombreContactoProveedor = document.getElementById('nombreContactoProveedor').value
        let apellidoContactoProveedor = document.getElementById('apellidoContactoProveedor').value
        let telefonoProveedor = document.getElementById('telefonoProveedor').value
        let correoProveedor = document.getElementById('correoProveedor').value

        if(nombreEmpresaProveedor !== '' && nombreContactoProveedor !== '' && apellidoContactoProveedor !== '' && telefonoProveedor !== '' && correoProveedor !== ''){
            let data = {
                nombreEmpresaProveedor : nombreEmpresaProveedor,
                nombreContactoProveedor : nombreContactoProveedor,
                apellidoContactoProveedor : apellidoContactoProveedor,
                telefonoProveedor : telefonoProveedor,
                correoProveedor : correoProveedor,
                empresaCliente : userData.nombreEmpresa,
                correoUsuario : userData.userEmail,
                mondayItem : userData.mondayItemId
            }

            let request = {
                method : 'POST',
                body: JSON.stringify(data)
            }

            fetch(proveedorApi,request)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                document.getElementById('proveedorForm').style.display = 'none';
                document.getElementById('proveedorFormSend').style.display = 'none';
                document.getElementById('contProv').classList.add("status-card-pending");
                closePopup();
            })
            .catch(error => console.log(error))

        }else{
            alert("Por favor llena todos los campos.")
        }
    };

    const Capitalize = (e) => {
        let string = e.target.value;
        let elmId = e.target.id;
        let capitalizedString;
        if(elmId === 'rfcEmpresa') capitalizedString = string.toUpperCase();
        else capitalizedString = string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });

        document.getElementById(elmId).value = capitalizedString;
    };

    //====================================================================

    //==========================================================
    //  Funcion para validar el correo y telefono
    //==========================================================

    const validateEmail = (e) => {
        var email = e.target.value

        if (validator.isEmail(email)) {
        //console.log('Valid Email :)')
        } else {
            alert('Ingresa un correo válido.');
        }
    };
    
    const validatePhone = (evt) => {
        let key = evt.target.valueAsNumber;

        if(key.toString().length === 10){console.log(' ')}
        else{alert('Verifica el numero')}
    };

    return (  
        <div>
             <div className='row popUp' style={{display:'none'}} id='popUp'>
                <div className='col-12'>
                    {/* POPUPS DE LAS TARJETAS */}

                    <h2 id='popUpTitle'></h2>
                    <label id='uploadFileLabel' htmlFor="uploadFile" className='GenericButton'>Selecciona tu archivo</label>
                    {/* <label id='uploadFileLabel' htmlFor="uploadFile" className='GenericButton'  onDragOver={handleDragOver} onDrop={handleDrop}>Selecciona tu archivo <br/> o <br/> Arr&aacute;stralo hasta aqu&iacute;</label> */}
                    {/* <input type='file' multiple id='uploadFile' value={selectedFile} style={{display:'none'}} onChange={fileHandling} accept="application/pdf"/> */}
                    <input type='file' id='uploadFile' value={selectedFile} style={{display:'none'}} onChange={fileHandling} accept="application/pdf"/>
                    
                    {/* COPIES PARA LAS TARJETAS DE FASE 1 */}

                    <div id="activo-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Te pedimos subir aquí la información del activo a arrendar, para realizar la cotización personalizada que necesitas.</p>
                        <p>Favor de adjuntar el archivo en formato PDF.</p>
                        <br></br>
                        <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="financieros-copy" style={{display:'none'}}>
                        <br></br>
                        <p>A continuación te pedimos subir aquí los documentos financieros Parciales y del Último Año.</p><br></br>
                        <p>¿Cuáles son los documentos y requisitos?</p><br></br>
                            <ul>
                                <li>- Balance General y Estado de Resultados del último año completo</li>
                                <li>- Balance General y Estado de Resultados del año corriente parcial</li>
                                <li>- Subir el archivo en formato PDF</li>
                            </ul><br></br>
                            <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="cuenta-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Para los Estados de Cuenta Bancarios de Cuenta Maestra, te pedimos por favor:</p><br></br>
                           <ul>
                                <li>- Subir el archivo en formato PDF o en el formato oficial del banco (no hoja de cálculo)</li>
                                <li>- Últimos 3 meses a partir del inmediato anterior</li>
                                <li>- Todas las hojas incluidas, incluyendo publicidad</li>
                                <li>- Si manejas de varios bancos, integra los estados de cuenta de todos ellos</li>
                            </ul><br></br>
                            <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="cv-copy" style={{display:'none'}}>
                        <br></br>
                        <p>A continuación, te pedimos incluir el CV de la empresa que solicita arrendamiento:</p><br></br>
                           <ul>
                            <li>- Nombre de la empresa</li>
                            <li>- Descripción del giro del negocio: Actividad principal, productos y servicios</li>
                            <li>- Estructura del grupo: principales socios y administradores. Indicar si pertenecen a grupo empresarial</li>
                            <li>- Antigüedad de la empresa</li>
                            <li>- Domicilio</li>
                            <li>- Sucursales, marcas y certificaciones</li>
                            <li>- Fotografías de la empresa</li>
                            <li>- Formato PDF</li>
                           </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="contCPP-copy" style={{display:'none'}}>
                        <br></br>
                        <p>En el siguiente formulario te pediremos información sobre las personas encargadas de las Cuentas Por Pagar.</p><br></br>
                        <p>Presiona "Empezar" cuando estés listo/a.</p><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>

                    {/* COPIES PARA LAS TARJETAS DE FASE 2 */}

                    <div id="actas-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Para continuar, solicitamos que subas estos 3 documentos:</p><br></br>
                           <ul>
                                <li>- Acta Constitutiva</li>
                                <li>- Inscripción al Registro Público de Comercio (RPC)</li>
                                <li>- Actualizaciones (en caso de existir)</li>
                            </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="domEmpresa-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Te pedimos seguir los siguientes lineamientos:</p><br></br>
                        <ul>
                            <li>- Subir archivo en formato PDF</li>
                            <li>- No mayor a 60 días</li>
                            <li>- Servicios aceptados: teléfono, luz o agua</li>
                            <li>- Domicilio capturado en solicitud</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="domAval-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Te pedimos seguir los siguientes lineamientos:</p><br></br>
                        <ul>
                            <li>- Subir archivo en formato PDF</li>
                            <li>- No mayor a 60 días</li>
                            <li>- Servicios aceptados: teléfono, luz o agua</li>
                            <li>- Domicilio capturado en solicitud</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="csfAval-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Para la Constancia de Situación Fiscal del Aval, te pedimos seguir los siguientes lineamientos:</p><br></br>
                        <ul>
                            <li>- Subir Documento en formato en PDF</li>
                            <li>- Subir el Documento en formato oficial y que describa las obligaciones fiscales</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="idAval-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Para la Identificacion del Aval, puedes subir cualquiera de los siguientes documentos:</p><br></br>
                        <ul>
                            <li>- INE o Pasaporte</li>
                            <li>- Vigente</li>
                            <li>- Copia por los dos lados y a color</li>
                            <li>- Copia legible (fotos y datos)</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="idRepLegal-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Para la Identificación de Representante Legal, puedes subir cualquiera de estos 2 documentos:</p><br></br>
                        <ul>
                            <li>- INE o Pasaporte</li>
                            <li>- Vigente</li>
                            <li>- Copia por los dos lados y a color</li>
                            <li>- Copia legible (fotos y datos)</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="rfcRepLegal-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Para el RFC de Representante Legal, te pedimos subir el documento con los siguientes requisitos:</p><br></br>
                        <ul>
                            <li>Formato en PDF</li>
                            <li>Formato oficial</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="podRepLegal-copy" style={{display:'none'}}>
                        <br></br>
                        <p>En caso de que los poderes del Representante Legal que solicita el financiamiento no sean otorgados en la Acta Constitutiva, anexar:</p><br></br>
                        <ul>
                            <li>- Acta de Asamblea en donde se otorgan los poderes</li>
                            <li>- Inscripción al Registro Público y de Comercio (RPC) de los Poderes</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="propComFirm-copy" style={{display:'none'}}>
                        <br></br>
                        <p>A continuación, te pedimos adjuntar aquí la Propuesta Comercial firmada.</p><br></br>
                        <p>¡Gracias!</p><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>

                    {/* COPIES PARA LAS TARJETAS DE FASE 4 */}

                    <div id="proOpinion-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Para la Opinión del Cumplimiento SAT, te pedimos seguir los siguientes lineamientos:</p><br></br>
                           <ul>
                                <li>- Subir documento en PDF</li>
                                <li>- Anitgüedad no mayor a 60 días</li>
                            </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="proConstanciaEmp-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Para la Constancia de Situación Fiscal, te pedimos seguir los siguientes lineamientos:</p><br></br>
                        <ul>
                            <li>- Subir Documento en formato PDF</li>
                            <li>- Subir el Documento en formato oficial y que describa las obligaciones fiscales</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="proConstanciaRep-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Para el RFC de Representante Legal, te pedimos subir el documento con los siguientes requisitos:</p><br></br>
                        <ul>
                            <li>Formato en PDF</li>
                            <li>Formato oficial</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="proIneRep-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Para la Identificación de Representante Legal, puedes subir cualquiera de estos 2 documentos:</p><br></br>
                        <ul>
                            <li>- INE o Pasaporte</li>
                            <li>- Vigente</li>
                            <li>- Copia por los dos lados y a color</li>
                            <li>- Copia legible (fotos y datos)</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="proDomicilio-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Te pedimos seguir los siguientes lineamientos:</p><br></br>
                        <ul>
                            <li>- Subir archivo en formato PDF</li>
                            <li>- No mayor a 60 días</li>
                            <li>- Servicios aceptados: teléfono, luz o agua</li>
                            <li>- Domicilio capturado en solicitud</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="proEdoCuenta-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Favor de subir aquí la Carátula del Estado de Cuenta en formato PDF, para que tengamos los datos a depositar</p><br></br>
                        <br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="proActas-copy" style={{display:'none'}}>
                        <br></br>
                        <p>Para continuar, solicitamos que subas estos 3 documentos:</p><br></br>
                        <ul>
                            <li>- Acta Constitutiva</li>
                            <li>- Inscripción al Registro Público de Comercio (RPC)</li>
                            <li>- Actualizaciones (en caso de existir)</li>
                            <li>- Los documentos pueden ser incluidos en el mismo archivo PDF o por separado, como prefieras</li>
                            <li>- Te pedimos que los documentos sean legibles y sin mutilaciones</li>
                            <li>- Validar nombres (completos y correctos) representante Legal</li>
                            <li>- Protocolizadas</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="proPoderes-copy" style={{display:'none'}}>
                        <br></br>
                        <p>En caso de que los poderes del Representante Legal que solicita el financiamiento no sean otorgados en la Acta Constitutiva, anexar:</p><br></br>
                        <ul>
                            <li>- Acta de Asamblea en donde se otorgan los poderes</li>
                            <li>- Inscripción al Registro Público y de Comercio (RPC) de los Poderes</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>
                    <div id="proFicha-copy" style={{display:'none'}}>
                        <br></br>
                        <p>A continuación te pediremos un poco de información más detallada del activo, con el fin de:</p><br></br>
                        <ul>
                            <li>- Agilizar el Proceso de Cierre Comercial</li>
                            <li>- Establecer rápidamente contacto</li>
                            <li>- Recabar los documentos correspondientes</li>
                        </ul><br></br>
                           <img src={logo} className='popUp-logo' alt="company logo"/>
                    </div>

                    {/* Formulario para la creacion del proveedor */}

                    <div id='proveedorForm' style={{display:'none'}}>
                        <table className='formProveedor'>
                            {/* <tbody> */}
                                <tr><td><label>Nombre de la Empresa</label></td></tr>
                                <tr><td><input id="nombreEmpresaProveedor" onBlur={(e) => Capitalize(e)}></input></td></tr>
                                <tr><td><label>Nombre del Contacto</label></td></tr>
                                <tr><td><input id="nombreContactoProveedor" onBlur={(e) => Capitalize(e)}></input></td></tr>
                                <tr><td><label>Apellido del Contacto</label ></td></tr>
                                <tr><td><input id="apellidoContactoProveedor" onBlur={(e) => Capitalize(e)}></input></td></tr>
                                <tr><td><label>Número de teléfono</label></td></tr>
                                <tr><td><input id="telefonoProveedor" type="number" onBlur={(e) => validatePhone(e)}></input></td></tr>
                                <tr><td><label>Correo electrónico</label></td></tr>
                                <tr><td><input id="correoProveedor" onBlur={(e) => validateEmail(e)}></input></td></tr>
                            {/* </tbody> */}
                        </table>
                    </div>

                    <div className='filesListMain'><ul id='filesList'></ul></div>

                    <div id='proveedorFormSend' className='popUp-footer' style={{display : "none"}}>
                        <button className='buttonSend' onClick={saveProveedorInfo}>Guardar</button>
                    </div>

                    <div id='typeformButton' className='typeformMain' onClick={e => lockCard(e)} style={{display : "none"}}>
                        <PopupButton className='typeform' style={{height:'50px'}} id='hn2H5Oca'>Empezar</PopupButton>
                    </div>

                    <div className='popUp-footer'>
                        <button className='button' onClick={closePopup}>Cerrar</button>
                        <button id='sendButton' className='buttonSend' onClick={sendFiles}>Enviar</button>
                    </div>
                </div>
            </div>

            {/* NOMBRE DE LA EMPRESA */}

            <h2 className="page-header">{userData.nombreEmpresa}</h2>
            <div className="row" id='costumerView'>

                <div className="row faseMain" id='faseRoad'>
                    <div className='faseWrapper'>
                        <div className='row fase active' id="fase1icon">
                            <i className='bx bx-buildings'></i>
                        </div>
                        <div className='faseTitle'>
                            <p style={{fontWeight: 'bold'}}>Paso 1</p>
                            <p>Expediente Empresarial</p>
                        </div>
                    </div>
                    <progress id="fase1-2" value={0}></progress>
                    <div className='faseWrapper'>
                        <div className='row fase inactive' id="fase2icon">
                            <i className='bx bx-briefcase-alt-2'></i>
                        </div>
                        <div className='faseTitle'>
                            <p style={{fontWeight: 'bold'}}>Paso 2</p>
                            <p>Expediente Legal</p>
                        </div>
                    </div>
                    <progress id="fase2-3" value={0}></progress>
                    <div className='faseWrapper'>
                        <div className='row fase inactive' id="fase3icon">
                            <i className='bx bx-user'></i>
                        </div>
                        <div className='faseTitle'>
                            <p style={{fontWeight: 'bold'}}>Paso 3</p>
                            <p>Contactos</p>
                        </div>
                    </div>
                </div>

                {/* TARJETAS DE FASES */}

                <div className="col-12">

                    {/* TARJETAS DE FASE 1 */}

                    <div className="row" id="fase1" style={{display: 'flex'}}>
                        {
                            Fase1.map((item, index) => (
                                <div className="col-4" key={index}>
                                    <StatusCard
                                        icon={item.icon}
                                        title={item.title}
                                        cardId={item.cardId}
                                        class={item.class}
                                    />
                                </div>
                            ))
                        }
                    </div>

                    {/* TARJETAS DE FASE 2 */}

                    <div className="row" id="fase2" style={{display: 'none'}}>
                        {
                            Fase2.map((item, index) => (
                                <div className="col-4" key={index}>
                                    <StatusCard
                                        icon={item.icon}
                                        title={item.title}
                                        cardId={item.cardId}
                                        class={item.class}
                                    />
                                </div>
                            ))
                        }
                    </div>

                    {/* TARJETAS DE FASE 3 */}

                    <div className="row" id="fase3" style={{display: 'none'}}>
                        {
                            Fase3.map((item, index) => (
                                <div className="col-4" key={index}>
                                    <StatusCard
                                        icon={item.icon}
                                        title={item.title}
                                        cardId={item.cardId}
                                        class={item.class}
                                    />
                                </div>
                            ))
                        }
                    </div>

                    {/* TARJETAS DE FASE 4 */}

                    <div className="row" id="fase4" style={{display: 'none'}}>
                        {
                            Fase4.map((item, index) => (
                                <div className="col-4" key={index}>
                                    <StatusCard
                                        icon={item.icon}
                                        title={item.title}
                                        cardId={item.cardId}
                                        class={item.class}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
                
                {/* FLECHAS DE NAVEGACION */}

                <div id='pagination' className='row page-icons' style={{display:'none'}}>
                    <div id='left-arrow' className='column' style={{display:'none'}}>
                        <i className='bx bx-left-arrow-alt arrow' onClick={antFase}></i>
                        <p>Atrás</p>
                    </div>
                    <div id='right-arrow' className='column'>
                        <i className='bx bx-right-arrow-alt arrow' onClick={sigFase}></i>
                        <p>Siguiente</p>
                    </div>
                </div>

            </div>

            {/* FORMULARIOS PARA MOFFIN (BUROS) */}

            <div className="row forms" id="buroEmpresarialForm" style={{display:'none'}}>
                <button onClick={goBack}>Atr&aacute;s</button>
                <iframe src={'https://tivos.moffin.co/solicitud/personamoral/comenzar'} scrolling="no"/>
            </div>
            <div className="row forms" id="buroAvalForm" style={{display:'none'}}>
                <button onClick={goBack}>Atr&aacute;s</button>
                <iframe src={'https://tivos.moffin.co/solicitud/aval/comenzar'} scrolling="no"/>
            </div>

            {/* FORMULARIO DE SATws */}

            <div className="row forms" id="satForm" style={{display:'none'}}>
                <button onClick={goBack}>Atr&aacute;s</button>
                <iframe src={'https://registro.satws.com/8a1c40'} scrolling="no"/>
            </div>

        </div>
    )
}
export default ClientDashboard
