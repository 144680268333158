//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React from 'react'

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('id');
const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
const solicitudBroker = process.env.REACT_APP_NEW_APPLICATION

const Application = () => {
    return (
        <div>
            <iframe src={solicitudBroker+`?id=${token}&userName=${userInfo.userName}&userEmail=${userInfo.userEmail}&userPhone=${userInfo.userPhone}`} scrolling="no"/>
        </div>
    )
}

export default Application
